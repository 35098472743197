
import PeoplesTypesService from "@/modules/peoples-types/peoples-types-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.create(payload).then(response => {
                resolve({
                    message: "PeoplesTypes criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.update(payload.id,payload).then(response => {
                resolve({
                    message: "PeoplesTypes atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.remove(id).then(response => {
                resolve({
                    message: "PeoplesTypes removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.delete(id).then(response => {
                resolve({
                    message: "PeoplesTypes deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.get(id).then(response => {
                resolve({
                    message: "PeoplesTypes encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.paginate(query).then(response => {
                resolve({
                    message: "PeoplesTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            PeoplesTypesService.all().then(response => {
                resolve({
                    message: "PeoplesTypes encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PeoplesTypes",
                    success: false,
                    error
                });
            })
        })
    }
}
